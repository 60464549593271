

// PS! Replace this with your own channel ID
// If you use this channel ID your app will stop working in the future
const CLIENT_ID = 'AevsEcTdddbDMcN8';
const channel = "ScaleDroneHelper"
//const CLIENT_ID = '8HgQaumhNR2jYRx2' // https://dashboard.scaledrone.com/channels/8HgQaumhNR2jYRx2




const scld = {
  x: scldModule(),
  y: function () { alert("scld") }

}

window.scld = scld
parent.scld = scld


function scldModule() {

  function add(message) {
    debugger

    //- ftp : alert on ScaleDrone Error Message
    // TODO : IndyHub Base should show log and error
    try {
      drone.publish({
        room: 'observable-room',
        message: message,
      });
    }
    catch (e) { alert(e) }


  }
  function getDrone() {



    const drone = new ScaleDrone(CLIENT_ID, {
      data: { // Will be sent out as clientData via events
        name: getRandomName(),
        color: getRandomColor(),
      },
    });
    let members = [];


    drone.on('open', error => {
      if (error) {
        return console.error(error);
      }
      console.log('Successfully connected to Scaledrone');

      const room = drone.subscribe('observable-room');
      room.on('open', error => {
        debugger
        if (error) {
          return console.error(error);
        }
        console.log('Successfully joined room');
      });

      room.on('members', m => {
        members = m;
        updateMembersDOM();
      });

      room.on('member_join', member => {
        members.push(member);
        updateMembersDOM();
      });

      room.on('member_leave', ({ id }) => {
        const index = members.findIndex(member => member.id === id);
        members.splice(index, 1);
        updateMembersDOM();
      });

      room.on('data', (text, member) => {
        debugger
        if (member) {
          addMessageToListDOM(text, member);
          if (text.startsWith('{')) {
            let message = JSON.parse(text)
            if (message.hasOwnProperty('rq')) {
              // dim
              debugger
              dim.rqs.requestHandler(message)
            }
            if (message.event === 'push') {
              dim.rqs.pushHandler(message)
            }
            // requestHandler(message)
          }
        } else {
          // Message is from server
        }
      });
    });

    drone.on('close', event => {
      console.log('Connection was closed', event);
    });

    drone.on('error', error => {
      console.error(error);

    });

    return drone
  }
  return { add, getDrone }
}




function getRandomName() {

  return localStorage.peerName



  const adjs = ["autumn", "hidden", "bitter", "misty", "silent", "empty", "dry", "dark", "summer", "icy", "delicate", "quiet", "white", "cool", "spring", "winter", "patient", "twilight", "dawn", "crimson", "wispy", "weathered", "blue", "billowing", "broken", "cold", "damp", "falling", "frosty", "green", "long", "late", "lingering", "bold", "little", "morning", "muddy", "old", "red", "rough", "still", "small", "sparkling", "throbbing", "shy", "wandering", "withered", "wild", "black", "young", "holy", "solitary", "fragrant", "aged", "snowy", "proud", "floral", "restless", "divine", "polished", "ancient", "purple", "lively", "nameless"];
  const nouns = ["waterfall", "river", "breeze", "moon", "rain", "wind", "sea", "morning", "snow", "lake", "sunset", "pine", "shadow", "leaf", "dawn", "glitter", "forest", "hill", "cloud", "meadow", "sun", "glade", "bird", "brook", "butterfly", "bush", "dew", "dust", "field", "fire", "flower", "firefly", "feather", "grass", "haze", "mountain", "night", "pond", "darkness", "snowflake", "silence", "sound", "sky", "shape", "surf", "thunder", "violet", "water", "wildflower", "wave", "water", "resonance", "sun", "wood", "dream", "cherry", "tree", "fog", "frost", "voice", "paper", "frog", "smoke", "star"];
  return (
    adjs[Math.floor(Math.random() * adjs.length)] +
    "_" +
    nouns[Math.floor(Math.random() * nouns.length)]
  );
}

function getRandomColor() {
  return '#' + Math.floor(Math.random() * 0xFFFFFF).toString(16);
}


//------------- DOM STUFF
const DOM = {
  membersCount: document.querySelector('.members-count'),
  membersList: document.querySelector('.members-list'),
  messages: document.querySelector('.messages'),
  input: document.querySelector('.message-form__input'),
  form: document.querySelector('.message-form'),
};

function updateMembersDOM() {
  if (!DOM.form) return
  DOM.membersCount.innerText = `${members.length} participants in channel ${channel} ':`;
  DOM.membersList.innerHTML = '';
  members.forEach(member =>
    DOM.membersList.appendChild(createMemberElement(member))
  );
}

function createMemberElement(member) {
  const { name, color } = member.clientData;
  const el = document.createElement('div');
  el.appendChild(document.createTextNode(name));
  el.className = 'member';
  el.style.color = color;
  return el;
}
function addMessageToListDOM(text, member) {
  const el = DOM.messages;
  if (!el) return
  const wasTop = el.scrollTop === el.scrollHeight - el.clientHeight;
  el.appendChild(createMessageElement(text, member));
  if (wasTop) {
    el.scrollTop = el.scrollHeight - el.clientHeight;
  }
}

function createMessageElement(text, member) {
  const el = document.createElement('div');
  el.appendChild(createMemberElement(member));
  el.appendChild(document.createTextNode(text));
  el.className = 'message';
  return el;
}

if (DOM.form) {

  DOM.form.addEventListener('submit', sendMessage);


  function sendMessage() {
    const value = DOM.input.value;
    if (value === '') {
      return;
    }
    DOM.input.value = '';
    drone.publish({
      room: 'observable-room',
      message: value,
    });
  }











}