import { app, Component } from 'apprun';

import './fs-helper.js'
declare var $: any
declare global {
  interface Window { loaded: any, wnfs: any, minddrive: any, lf: any, x: any }
}
export default class ToolsComponent extends Component {
  state = 'Tools';

  view = state => <div>
    <h1>{state}</h1>
    <p>Choose file, then press read file, then load dump</p>
    <input type="file" id="file-input" />
    <button id="dump-button" $onclick="dump">Dump localStorage</button>
    <button id="read-button" $onclick="read file">Read File</button>
    <pre id="file-contents">
      {state.content}
    </pre>


    <button title="hard wired emergency loading of dump" $onclick="load-dump" >Load dump</button>

  </div>;

  update = {
    '#Tools': state => state,
    'dump': state => {

      state
    },
    'read file': state => {
      // https://usefulangle.com/post/193/javascript-read-local-file document.querySelector("#read-button").addEventListener('click', function() {
      debugger
      let file
      let fileObjects = document.querySelector("#file-input");
      if (fileObjects) {
        let files = fileObjects['files']
        file = files[0]
      }
      let reader = new FileReader();
      debugger
      reader.addEventListener('load', function (e) {
        debugger
        if (e.target) {
          let text = e.target.result;
          window.loaded = text
        }

      });
      reader.readAsText(file);

      return state

    },

    'load-dump': async (state) => {

      //'20211221-http___localhost_8081_demo_#archive'
      if (state.dumpfile) {
        localStorage.dumpfile = state.dumpfile
      }

      if (window.wnfs) {
        let x = await window.wnfs.get('private/MindDrive/Archive/' + state.dumpfile + '.json')
        let dump = JSON.parse(new TextDecoder('utf-8').decode(x.content))
        window.minddrive.dump = dump


        let dbName = "lfDotStore" + state.username
        window.minddrive.dotStore = window.lf.createInstance({
          name: dbName,
          storeName: 'dotStore'
        });


        function load2dotStore(json) {
          let keys = Object.keys(json)
          console.log(keys)
          keys.forEach(async function (k, i) {

            let dotString = json[k]
            if (dotString && dotString !== "undefined") {
              try {
                console.log(i, k)
                let d = JSON.parse(dotString)
                console.log(i, d.t)
                let x = await window.minddrive.dotStore.setItem(k, d)
                if (false) {
                  let inLS = localStorage.getItem(k)
                  if (inLS === "[object Object]") {
                    inLS = ""
                  }
                  if (!inLS) {
                    d.b = '- archived : ' + d.a
                    localStorage.setItem(k, JSON.stringify(d))
                  }
                }
              }
              catch (e) {
                console.error(e, k)
              }
            }
          })
        }
        load2dotStore(dump)
      }
      else {
        // var fileName = './dumps/dump-http___localhost_8080.json'
        // fileName = './dumps/dump-https___hub.opidox.com.json'
        debugger



        const json = JSON.parse(window.loaded)
debugger
        // load to local storage
        function load2localStorage(loaded) {
          Object.keys(loaded).forEach(function (a) {
            localStorage[a] = loaded[a];
          })
        }
        if (localStorage.length < 30) {
          load2localStorage(json)
        }
        return Object.keys(json)
      }
    },


  };

  //- ftp : show emojis in browser tabs for app pages
  rendered = state => {
    $('title').html('🧰 tools | IndyLab')
  }
}

