import app, { Component } from 'apprun';
import { mindgraph } from '../docs/js/mindgraph.js'
//import 'localforage'../

const floatRight = { float: 'right' }

declare global {
  interface Window { mg5: any }
}
window.mg5 = mindgraph

//- feat : link to deployed version
function isDeployed() {
  const match = 'fission.app'
  const host = location.host
  const bit = host.substring(host.length - match.length)
  console.log(bit)
  return bit === match
}
export default class HomeComponent extends Component {

  state = { title: 'Home', version: localStorage.IndyLabBuildDate, build: 'teal-steel-cat.fission.app' };
  // https://indylab1.fission.app/hyp/?apprun%20style


  view = (state) => {
    return <div>
      <em style={floatRight}>{state.version}</em>
      <h1>{state.title}</h1>

      {isDeployed() ?
        <a title="dev build" target="_blank" href="http://localhost:8080">local</a> :
        <a title="latest fission build" target="_blank" href="https://teal-steel-cat.fission.app?plugout=IndyHub&date=2022 02 22">{state.build}</a>
      }
      <br></br>
        <a title="Convert Google Docs To HTML" target="_blank" href="/gdoctohtml/">Google Docs to HTML</a>

      <br></br>
      <button $onclick="clear cache">clear app cache</button>
      <a target="_blank" href="https://gitlab.com/trailhub/indihub-pilot">
        <img src="https://bafybeids74o6ji62wsi64mg3nbwtaemxr2y7tl2x66u332ip62hwuks3ue.ipfs.dweb.link/IndieHubOldLogo.png"
        ></img>indiehub-pilot
      </a>
      xxx yyy
    </div>
  }

  update = {
    '#Home': state => state,
    'clear cache' : state => {
      let cleared = ""
      if ('serviceWorker' in navigator) {
        debugger
        caches.keys().then(function(cacheNames) {
          cacheNames.forEach(function(cacheName) {
            debugger
            cleared = cleared + cacheName
            console.log(cacheName)
            caches.delete(cacheName);
          });
        });
      }
      alert("Cache Cleared " + cleared)
      state
    }
  }

}

