import app, { Component } from 'apprun';
declare global {
  interface Window { hub: any }
}
export default class AboutComponent extends Component {
  state = 'About';

  view = (state) => {
    return <div>
      <h1>{state}</h1>
    </div>
  }

  update = {
    '#About': state => state
  }

  rendered = state => {

    if (window.hub) {
      console.log(window.hub)
    }
    else {
     location.href='/#Chat'
    }
  }
}

