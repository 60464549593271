import app from 'apprun';
import Home from './Home';
import About from './About';
import Tools from './Tools';
import Chat from './Chat';
import Contact from './Contact';
debugger
import SlipStreamComponent from './components/search-type-ahead';
import SlipStream from './SlipStream'

//import {mindgraph} from './js/mindgraph.js'



declare global {
  interface Window {moment:any,MG4}
}

debugger
//
//import * as moment  from "../node_modules/moment/min/moment.min.js"
//window.moment = moment
app.on('//', route => {
  const menus = document.querySelectorAll('.navbar-nav li');
  for (let i = 0; i < menus.length; ++i) menus[i].classList.remove('active');
  const item = document.querySelector(`[href='${route}']`);
  item?.parentElement?.classList.add('active');
})

const App = () => <div class="container">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="/"><img width="48" src="http://gateway.pinata.cloud/ipfs/bafkreia2y7hbab46vjk7sgdcdzxann2moyeeflothfejwgtbjkthfkfw7q?filename=icon%2520-%2520IndyLab.png"></img
    >Indy0</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" href="#Home">Home
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item">
          <a target="_blank" title="Reverse chronological listing of search result"class="nav-link" href="#SlipStream">SlipStream</a>
        </li>
        <li class="nav-item">
          <a target="_blank" class="nav-link" href="#Chat">Chat</a>
        </li>
        <li class="nav-item">
          <a target="_blank" class="nav-link" href="../reveal/?slides">Reveal</a>
        </li>
        <li class="nav-item">
          <a target="_blank" class="nav-link" href="#Tools">Tools</a>
        </li>
        <li class="nav-item">
          <a target="_blank" class="nav-link" href="../hyp/facet">(an)Not(e)</a>
        </li>
        <li class="nav-item">
          <a target="_blank" class="nav-link" href="../web3">⁂ web3.storage</a>
        </li>
        <li class="nav-item">
          <a target="_blank" class="nav-link" href="#About">About</a>
        </li>
        {
        /** <li target="_blank" class="nav-item">
          <a class="nav-link" href="#Contact">Contact</a>
        </li> */} 
      </ul>
    </div>
  </nav>
  <div class="container" id="my-app"></div>
  <a class="navbar-brand" href="#"><img width="48" src="http://gateway.pinata.cloud/ipfs/bafkreia2y7hbab46vjk7sgdcdzxann2moyeeflothfejwgtbjkthfkfw7q?filename=icon%2520-%2520IndyLab.png"></img
    >Inter <img src="https://gateway.pinata.cloud/ipfs/bafybeignnlwvmvwxsgxkwhocegzwuqe4vxptggjicwvptu6a4436hwa7wu/hypothesis@mindgraph.png"></img>🌐⛭🗫Wiki/Hub/Lab</a>
   
</div>

app.render(document.body, <App />);

const element = 'my-app';
new Home().start(element);
new Tools().mount(element);
new About().mount(element);
new Chat().mount(element);
new Contact().mount(element);
new SlipStreamComponent().mount(element)
new SlipStream().mount(element)




//app.on('/wiki', () => app.run('#Chat'))

import './pwa';
