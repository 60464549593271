
declare var opidox: any;
//import {MindGraph} from '../../public/js/mindgraph.js'

//import { opidox } from '../../public/js/mg2.js';
debugger
import { mindgraph } from '../../src/js/mindgraph2.js'
debugger

var searcher = (function () {
    //- ftp : Sort as you search 
    function sortByPropComparator(prop) {
        return function (a, b) {
            if (a[prop] && b[prop]) {
                return a[prop] - b[prop]
            }
            else return true
        }

    }
    //- ftp : Search in dot body too
    // - for : - issue : Make Usability improvement in TrailNex
    function searchHelper(text) {
        // - capability : search for multiple terms
        return searchByTerm(text)

    }

    function searchByTerm(term) {
        debugger
        let byTitle = mindgraph.dot.byTitle(term).sort(sortByPropComparator("lu"))
        //- ftp : Search by stub before body
        let byStub = mindgraph.dot.byProp("s", term).sort(sortByPropComparator("lu"))
        let byBody = mindgraph.dot.byProp("b", term).sort(sortByPropComparator("lu"))
        //- dot : union arrays JavaScript | Stack Overflow
        return [...new Set([...byTitle, ...byStub, ...byBody])];
    }
    return {
        //  dots: states,



        search: (text, chunkSize) => {
           debugger
            if (!chunkSize) {
                chunkSize = 50
            }


            // require minimum of two characters
            debugger
            mindgraph.dot.getMap(true)
            //- fix q&d : byDaysBefore need reversing for chunking
            return text.length < 2 || text === ' ' || text === ''
                ? mindgraph.dot.byDaysBefore(130).reverse().splice(0, chunkSize).reverse()
                : searchHelper(text).splice(0, chunkSize)
        },
        call: (component, text) => component.run('input', text)
    }
})()

// Capability Transfer Object dynamically adding capabilities
var opidox = opidox || {}
opidox.cto = opidox.cto || {}
opidox.cto.searcher = opidox.cto.searcher || searcher

// TrailHub Data Transfer Object common methods
var trailhub = trailhub || {}
trailhub.dto = trailhub.dto || {}
trailhub.dto.selfLink = function (dot) {
    return location.href.substring(0, location.href.lastIndexOf('/search')) + '/view/' + dot.t
}




import { app, Component, customElement } from 'apprun';
//import { optionHandlers } from '../../public/js/codemirror/codemirror.js';
//import codemirrorJs from './codemirror-js.js';
//import codemirrorJs from './codemirror-js.js';
// jQuery is loaded from index.html for app derived from layout/index.html
declare var $: any
//declare var opidox: any
declare var M: any

const alignTop = { display: "inline-block", "vertical-align": "top" }
const floatRight = { float: "right" }
const floatLeft = { float: "left" }
//http://www.javascriptkit.com/javatutors/loadjavascriptcss.shtml

function loadjscssfile(filename, filetype) {

    var fileref
    if (filetype == "js") { //if filename is a external JavaScript file
        fileref = document.createElement('script')
        fileref.setAttribute("type", "text/javascript")
        fileref.setAttribute("src", filename)
    }
    else if (filetype == "css") { //if filename is an external CSS file
        fileref = document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", filename)
    }
    if (typeof fileref != "undefined")
        document.getElementsByTagName("head")[0].appendChild(fileref)
}

function old(option) {
    <>  <div className="row">
        <div className="col s3">
            <a href={option.ref}>
                <img title={option.ref} height="80" src={option.ii}>
                </img>
            </a>

        </div>
        <div className="col s9">
            <div className="row">
                <div className="col s2">
                    <img width="32" src={option.i}></img>
                </div>

                <div class="col s10">
                    <button title="Open Editor in old Hub" style={floatRight}
                        $onclick={() => { this.run("edit", option) }}>&#8942;</button>
                    <b> {option.t} </b>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    {option.s}
                </div>
            </div>
        </div>
    </div>
    </>
}

/**
 * In local storage have objects with invalide names like @class and @c
 * Need to remove them
 * @param o 
 */
function fix(o) {
    delete o["@class"]
    delete o["@c"]
    return o
}
const rowBottom = { "margin-bottom": "2px" }
const dateStyle = { "font-size": "0.5em" }
//loadjscssfile("./css/search-type-ahead.css", "css")
@customElement('search-type-ahead')
export default class extends Component {
    compact = false;
    floatright = { "float": "right" }

    //
    view = state => {

        const styles = { float: "left", display: "block", width: "90px" }
        function process(x) { debugger 
            create$iframe4dotBody(x, null) }

        //- fn : create$iframe4dotBody
        /**
         * @param dot MindGraph dot object
         * @param dotElement htmlElement for the dot assume the presence of a class 'b' marking the div where the body for the dot will be added
         */
        function create$iframe4dotBody(dotElement, dot) {
            debugger
            /**
             * dot Body is marked with class .b
             * @param dotElement HTML Element for Dot
             */
            function get$BodyDivInDot(dotElement) {
                return $('.b', dotElement)
            }
            /**
             * check if BodyDiv has a first element child as it is an iframe
             * @param bodyDiv 
             */
            function noIframeInBodyDiv(bodyDiv) {
                let child = bodyDiv[0].firstElementChild
                return !child || child.tagName !== "IFRAME"
            }

            //- bug : do not close dot body iframe when showing all
            let dotGiven = true
            if (!dot) {
                dotGiven = false
                let a = dotElement.id
                dot = mindgraph.dot.byId(a)
            }
            let bodyDiv = get$BodyDivInDot(dotElement)
            let content = bodyDiv.html()

            //- ftp : toggle showing body
            if (content && dotGiven) {
                // only reset body of dot is given
                bodyDiv.html('')

            }

            if (noIframeInBodyDiv(bodyDiv)) {

                const HTMLToIsolate = dot.b// get your div tag as HTML string
                const parentContainerRef = bodyDiv[0]; // or something else
                let newIframe = document.createElement('iframe');
                // set height & width to 100% and remove borders for newIframe

                parentContainerRef.appendChild(newIframe)
                newIframe.width = "100%"
                newIframe.height = "60px"
                newIframe.frameBorder = "0"
                let style =
                    "body { \n\
font-family: 'Roboto', 'Noto', sans-serif;\n\
-webkit-font-smoothing: antialiased;\n\
color: #222222;\n\
font-size: 16px;\n\
line-height: 1.5;\n}"

                
                    const cwindow = newIframe.contentWindow
                    if (cwindow) {
                        cwindow.document.open();
                        let html = "<html><head><style> " + style + "</style></head><body><div class='b'>"
                            + HTMLToIsolate +
                            "<div></body></html>"
                        cwindow.document.write(html);
                        cwindow.document.close();

                        // bonus to remove scroll bars by setting proper iframe's height
                        newIframe.addEventListener('load', function onIFrameLoad(e) {
                            const cdoc = this.contentDocument
                            if (cdoc) {
                                let x = $('.b', cdoc.body)[0]

                                let textLength = x.getBoundingClientRect().height + 30

                                if (textLength > 180) {
                                    textLength = 180
                                }
                                newIframe.style.height = textLength + 'px';

                            }

                        });
                    } 
              

                }

        }

            return <>

                <div className="typeahead">
                    <span>

                        <button
                            $onclick="clear">CLEAR</button>   <b>{state.options ? state.options.length : ''}</b>
                        &nbsp;local storage statsx &nbsp;
                        <select style={styles} onchange={(e) => this.run('changeChunkSize', e.target.value)}>
                            <option value="5000">&nbsp;&nbsp;all</option>
                            <option value="50" selected >&nbsp;&nbsp;50</option>
                            <option value="100">&nbsp;&nbsp;100</option>
                            <option value="250" >&nbsp;&nbsp;250</option>
                            <option value="500" >&nbsp;&nbsp;500</option>
                            <option value="1000">1000</option>
                        </select>
                        {/* - ftp : show local storage stats on search page */}
                        {mindgraph.dot.stats()}

                        <input
                            id="searchInput"
                            type="text"
                            placeholder="Search:"
                            autocomplete="off"
                            value={state.selected || ''}
                            oninput={e => {
                                debugger
                                this.run('search', e)}}
                            onkeydown={e => this.run(`keydown`, e)}
                        />

                    </span>

                    <button title="create new dot"
                        $onclick={() => this.run("new-dot")}>NEW</button>

                    <button title="Show body for all dots"
                        //- capability : Show All dot bodies ✓
                        $onclick={() => {

                            $('.dot').toArray().map(process)
                        }}>Show All</button>



                    <ul>{state.show && state.options.length ?

                        state.options.map(option => (

                     
                            <li className={option === state.selected ? 'selected' : ''}
                                onclick={(ev) => {
                                    //- capability : click result item show/hide body
                                    // get element of class dot
                                    let dotElement = ev.target.closest(".dot")
                                    //- ftp : add iframe with dot body as content
                                    create$iframe4dotBody(dotElement, option)
                                    $(window).scrollTop(ev.movmentY);
                                }
                                }>
                                <b>{option.t}</b>
                                <div className="dot head" id={option.a}>
                                    <div className="row" style={rowBottom}>
                                        {/** - Q&D fix : layout page icon with float left */}
                                        <div style={floatLeft}>
                                            <a href={option.ref}>
                                                <img title={option.ref} height="50" src={option.ii}>
                                                </img>
                                                {/* $onclick={() => this.run("#dot", 'edit', encodeURIComponent(option.t)) */}
                                            </a>
                                        </div>
                                        {/* make column for dot image smaller */}
                                        <div className="col s11">

                                            <div className="row" style={rowBottom}>
                                                {/* make second column larger */}
                                                <div class="col s11">
                                                    <img width="16" src={option.i}></img>
                                                    {/*//- improve UI : move & fix Edit ✎ link */}

                                                    <span style={floatRight}>

                                                        <span style={dateStyle}>
                                                            {/*- ftp : view dot in new tab */}
                                                            <a title="View dot" target="_blank" href={option.selfLink}>
                                                                {mindgraph.transform.debug(false)}
                                                                {mindgraph.utils.formatTime(option.le, option.lu, option.ct)}
                                                            </a>
                                                        </span>

                                                        <button title="Open Editor in new tab"
                                                            $onclick={() => {

                                                                let href = './rte/?' + option.t
                                                                window.open(href, '_blank')

                                                            }}>EDIT</button>{state.ix++}</span>

                                                    <b> {option.t} </b>
                                                </div>
                                            </div>
                                            <div className="row" style={rowBottom}>
                                                <div className="col s12">
                                                    {option.s}

                                                </div>


                                            </div>
                                            {/* remove horizontal line */}
                                        </div>

                                    </div>


                                    &nbsp; <div class="b" id={option.a} />

                                </div>



                            </li>
                        )



                        ) : ''}</ul>
                </div>
                <div id="modal"></div>

            </>
        }



        update = {
            'input': (state, dot) => {

                //   alert(dot.b)


                const view = (state) => <> <mtrl-modals
                    mid="modal1"
                    auto="true"
                    what={dot.t}
                    a={dot.a}
                >
                </mtrl-modals>
                </>
                $('#modal').html("")
                let elem = document.getElementById('modal')
                if (elem) {
                elem.innerHTML = ""
                app.start(elem, dot, view)
                }
            },

            search: [(state, e) => {
                // first example of the use of Capability Transfer Object
                let options;
                debugger
                options = opidox.cto['searcher'].search(e.target.value, state.chunkSize).reverse()
                options = options.map(dot => {
                    // save src for first image to be displayed in list/compact
                    // this way we see an image representative of the content of the dot
                    // while in a smaller format see the saved icon for the dot
                    // this is the favicon for saved resources
                    // TODO whould be part of setting up map

                    let imageSrc = ""
                    let ref = ""
                    try {
                        imageSrc = $('img', '<div>' + dot.b + '</div>').first().attr("src")
                        ref = $('a', '<div>' + dot.b + '</div>').first().attr("href")
                    } // dots created on android may have plain text at the beginning that jquer crashes on
                    catch (e) {
                        console.log(dot.t, e)
                    }

                    dot.ref = ref ? ref : ''
                    dot.ii = dot.i
                    if (imageSrc) {
                        dot.ii = imageSrc
                    }
                    dot.selfLink = trailhub.dto.selfLink(dot)
                    return dot
                })
debugger
                return {
                    ...state,
                    show: true,
                    selected: e.target.value,
                    ix: 1,
                    options
                };
            },
            { delay: 200 }],

            clear: (state) => {
                state.selected = ''
                debugger
                state.options = []
                this.setState(state)
                $('#searchInput').focus()
            },
            popup: (state, show) => (state.show === show ? null : { ...state, show }),
            keydown: (state, e) => {

                if (!state.options) return;
                let selectedIdx = state.options.indexOf(state.selected);
                switch (e.keyCode) {
                    case 27:
                        return { ...state, show: false };
                    case 38:
                        selectedIdx--;
                        if (selectedIdx < 0) selectedIdx = 0;
                        return { ...state, selected: state.options[selectedIdx] };
                    case 40:
                        selectedIdx++;
                        if (selectedIdx >= state.options.length) selectedIdx = state.options.length - 1;
                        return { ...state, selected: state.options[selectedIdx] };
                    case 13:
                        e.preventDefault();
                        this.run('select', state.selected);
                }
            },
            select: (state, selected) => {
                localStorage.CN = selected.a
                debugger
                opidox.cto[state.searcher].call(this, selected);


                // return { ...state, title, show: true };
            },
            modal: (state, option) => {

            },
            editNew: (state, selected) => {
                alert(selected.t)
            },
            edit: (state, what) => {

                //let href = 'https://' + mindgraph.utils.getLocalIP() +'/modules/rte4mg/?t=' + encodeURIComponent(selected.t)
                //let href = 'http://' + mindgraph.utils.getLocalIP() + '/rte/?t=' + encodeURIComponent(selected.t)

                let RTEref = '/#dot/edit/'//'https://gyuri.opidox.com/demo'
                let href = RTEref + encodeURIComponent(what)

                var win = window.open(href, '_blank');

                //  var win = window.open(href, '_blank');
                //  win.focus();
                return state
                /**
                 * https://gyuri.opidox.com/modules/ui/?t=%E2%9C%A8%20Holonic%20Component%20Model
                 */
            },

            'changeChunkSize': (state, value) => {
                let c = parseInt(value)
                // this.chunkSize = localStorage.length
                console.log(state, value)
                return { ...state, chunkSize: c }
            },
            'new-dot': (state, selected) => {
                let x = $('#searchInput').val()
                debugger
                console.log(x)
                let dot = { t: x ,s: x, i: 'https://bafybeihvcrddb4zbq5t4zuj7q4esabf5mizoxvkjvoac7gmmf73fcnqou4.ipfs.w3s.link/dot.png'}
                debugger
                dot = mindgraph.dot.createFromJSON(dot)
               // opidox.cto[state.searcher].call(this, dot);

            }
        };

        rendered = (state) => {

            //- uxi : list recent in Find or matching /?<title>
            //- fix : avoid loop and allow trailing slash and even ?
            debugger
            $('#searchInput').click(function (e) {
                debugger
                if (e.target.value=' ') {
                e.target.value=""
                }
            })
            debugger
            if (!state.selected) {
                state.what = ' '
                let indexOfQuestionMark = location.href.indexOf("?")
                if (indexOfQuestionMark > 0) {
                    let searchBit = location.href.substring(indexOfQuestionMark + 1)
                    if (searchBit.length > 0)
                        state.what = searchBit
                }

                this.run('search', { target: { value: decodeURIComponent(state.what) } })
               // state.what = ""
               if ($('#searchInput').val()===' ') {
                $('#searchInput').val('')
               }

              //  return state
            }
            let options = {}

            var elems = document.querySelectorAll('.collapsible');

            var instances = M.Collapsible.init(elems, options);

            function process(x) { let dot = mindgraph.dot.byId(x.id); if (dot.b) { x.innerHTML = dot.b } }
            /*
                let x = $('.b').toArray()
                x.map(process)
                */

        }

    }