import { app, Component } from 'apprun';

import OrbitDB from 'orbit-db'
// jQuery is loaded from index.html for app derived from layout/index.html
//import {IPFS} from 'ipfs'
//import OrbitDB from 'orbit=db'
declare var $: any
//import {hub}  from '../public/hub/chat/hub.js'
//import  from '../public/hub/chat/odb';
declare global {
  interface Window { hub: any }
}

/**
 * 2023 02
 * 24 move save button
 */




export default class AboutComponent extends Component {
  floatRight = 'float:right'
  state = {
    title: 'Huddle Chat',

    buildDate: localStorage.IndyLabBuildDate
  };

  
  view = state => <>

    <span> <b>{state.title}</b>&nbsp;<div style={{ float: "right" }} >{state.buildDate}</div></span>
    <input style={{width:"50%"}} title="Enter a nickname for yourself and the device you are using" 
      id="inputPeerName" type="text" value={state.peerName}></input>
      <span  >
      <button $onclick="save peerName">SAVE</button>
    <a title="Pick unicode symbol for nickname, add at the beginning" 
      target="_blank" 
      href="https://unicode-table.com/en/sets/symbols-for-nickname/#popular">
      Pick Unicode nickname
    </a>
    </span>

    &nbsp;{state.link}
    <iframe id="chatFrame" src='./hub/chat/index.html' width="640" height="600"></iframe>
  </>;


  update = {
    // - dev : near real time collaboration

    '#Chat': (state, a) => {

      const link = isScribe() ? <a target="_blank" href="https://dashboard.scaledrone.com/channels">SclaDrone Channels</a> : ''
      // show peerName
      const peerName = localStorage.peerName
      //  const peerName = ''
      //  hub.odb.setupOwnHub()
      return { ...state, link, peerName }
    },
    /**
     * - ftp : show/edit peerName save in localStorage
     */
    "save peerName": () => {
      const peerName = $('#inputPeerName').val()

      debugger
      localStorage.peerName = peerName
    }
  }
  rendered = state => {

    // $('head').append('<link rel="icon" type="image/png  href="/icon - IndyHubx.png">huddle | IndyLab')
    $('title').html('🀝 huddle | IndyHub')
    console.log("rendered again")
    debugger
    function noHash() {
      const index = location.href.indexOf('#')
      if (index > 0) {
        const newRef = location.href.substring(index + 1)
        console.log(newRef)
        //    history.pushState(null, null, newRef)
      }
    }

  }
}

function isScribe() {
  return localStorage.peerName === "localTrailMaster"
}
