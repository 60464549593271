import app, { Component } from 'apprun';
import  './js/drone.js'
export default class ContactComponent extends Component {
  state = 'Chat';

  view = (state) => {
    return <div>
      <h1>{state}</h1>
      <div class="members-count">-</div>
      <div class="members-list">-</div>
      <div class="messages"></div>
      <form class="message-form" onsubmit="return false;">
        <input class="message-form__input" placeholder="Type a message.." type="text" />
        <input class="message-form__button" value="Send" type="submit" />
      </form>
      <a target="_blank" href="https://dashboard.scaledrone.com/channels"><img width="32px" src="https://dashboard.scaledrone.com/img/symbol_blank.svg"> </img>   </a>
    
    </div>
  }

  update = {
    '#Contact': state => state,
  }

  rendered = (state) => {
    

  }
}

