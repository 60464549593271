import { app, Component } from 'apprun';
import {mindgraph} from '../src/js/mindgraph2'

debugger
console.log(mindgraph.dot.version)

declare var $: any
export default class SlipStreamComponent extends Component {
    state = 'SlipStream';
  debugger
    view = state => <div>
        <b>{state}</b>
        
        <search-type-ahead></search-type-ahead>
    </div>

    update = {
        '#SlipStream': state => {
            debugger
            return state
        }


    }
    //- ftp : show emojis in browser tabs for app pages
    rendered = state => {
        debugger
        $('title').html('🔍 SlipStream - IndyLab')
       let iconSrc = 'https://ipfs.io/ipfs/bafkreidhvv2zx2z3kh7x4o22v7nfclpaqap2byzrqxskzurl4fuu4gwxdy?filename=SlipStream'
        $('link[rel="icon"]').attr('href', iconSrc)
    }
}
